import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get, del } from 'api/fetch';
import { getClaimStatus } from 'views/MemberProfile/MyClaims/helpers';

export const fetchActivePlans = createAsyncThunk(
  'remote_health/MemberProfile/MyInsurance/fetchActivePlans',
  async () => {
    const response = await get('/rh/contracts/get-by-user', { api: 'rh' });
    return response;
  }
);

export const fetchUserInfo = createAsyncThunk(
  'remote_health/MemberProfile/MyInsurance/fetchUserInfo',
  async () => {
    const response = await get('/user/get-info', { api: 'rh' });
    return response;
  }
);

export const fetchClaims = createAsyncThunk(
  'remote_health/MemberProfile/MyInsurance/fetchClaims',
  async () => {
    const response = await get('/claim/list', { api: 'rh' });
    return response;
  }
);

export const deleteClaim = createAsyncThunk(
  'remote_health/MemberProfile/MyInsurance/deleteClaim',
  async (id, { rejectWithValue }) => {
    try {
      await del(`/claim/${id}`, null, {
        api: 'rh'
      });
      return id;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const slice = createSlice({
  name: 'remote_health/MemberProfile/MyInsurance',
  initialState: {
    activePlans: {
      isLoading: true,
      error: null,
      data: null
    },
    userInfo: {
      isLoading: true,
      error: null,
      data: null
    },
    claims: {
      isLoading: false,
      error: null,
      data: null,
      enabled: false
    }
  },
  extraReducers: {
    [fetchActivePlans.pending]: (state) => {
      state.activePlans.isLoading = true;
      state.activePlans.error = null;
    },
    [fetchActivePlans.fulfilled]: (state, { payload }) => {
      state.activePlans.isLoading = false;
      state.activePlans.error = null;
      state.activePlans.data = payload.length === 0 ? null : payload;
    },
    [fetchActivePlans.rejected]: (state, { error }) => {
      state.activePlans.isLoading = false;
      state.activePlans.error = error;
      state.activePlans.data = null;
    },
    [fetchUserInfo.pending]: (state) => {
      state.userInfo.isLoading = true;
      state.userInfo.error = null;
    },
    [fetchUserInfo.fulfilled]: (state, { payload }) => {
      state.userInfo.isLoading = false;
      state.userInfo.error = null;
      state.userInfo.data = payload;
    },
    [fetchUserInfo.rejected]: (state, { error }) => {
      state.userInfo.isLoading = false;
      state.userInfo.error = error;
      state.userInfo.data = null;
    },
    [fetchClaims.pending]: (state) => {
      state.claims.isLoading = true;
      state.claims.error = false;
    },
    [fetchClaims.fulfilled]: (state, { payload }) => {
      state.claims.isLoading = false;
      state.claims.error = false;
      state.claims.data = payload.map((claim) => {
        claim.computedStatus = getClaimStatus(
          claim.submissionStatus,
          claim.status,
          claim.paymentCardClaim
        );
        return claim;
      });
    },
    [fetchClaims.rejected]: (state, { error }) => {
      state.claims.isLoading = false;
      state.claims.error = error;
      state.claims.data = [];
    },
    [deleteClaim.pending]: (state) => {
      state.claims.isLoading = true;
      state.claims.error = false;
    },
    [deleteClaim.fulfilled]: (state, { payload }) => {
      state.claims.isLoading = false;
      state.claims.error = false;
      state.claims.data = state.claims.data.filter((c) => c.id !== payload);
    },
    [deleteClaim.rejected]: (state, { error }) => {
      state.claims.isLoading = false;
      state.claims.error = error;
    }
  }
});

export const { actions } = slice;

export default slice.reducer;
