import posthog from 'posthog-js';
import { PostHogProvider as Provider } from 'posthog-js/react';
import { type ComponentProps } from 'react';

export * from './components/PostHogPageView';

const POSTHOG_KEY = process.env.REACT_APP_PUBLIC_POSTHOG_KEY;
const POSTHOG_HOST = process.env.REACT_APP_PUBLIC_POSTHOG_HOST;

if (POSTHOG_KEY) {
  posthog.init(POSTHOG_KEY, {
    api_host: POSTHOG_HOST,
    capture_pageview: false,
    capture_pageleave: true
  });
}

export const PostHogProvider = ({ children }: ComponentProps<typeof Provider>) => {
  return <Provider client={posthog}>{children}</Provider>;
};
